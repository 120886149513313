"use client";

import { /* React, */ useLayoutEffect, useState } from "react";
import { redirect } from "next/navigation";
// import ContentLayout from "./layouts/ContentLayout";
// import AppText from "./AppText";
// import { AppButton } from "./buttons/AppButton";
import { usePathname /* useRouter */ } from "next/navigation";
import { useLocale } from "@/store/clientStore";
import type { Locale } from "@/i18n.config";

const NotFoundComponent = () => {
  const [dict, setDict] = useState<{ [key: string]: string } | null>(null);
  const { locale } = useLocale();

  // const router = useRouter();
  const pathname = usePathname();

  const getDict = async (lang: Locale) => {
    const dictionaries = {
      en: () => import("@/dictionaries/en.json").then((module) => module.default),
      fr: () => import("@/dictionaries/fr.json").then((module) => module.default),
    };

    const getDictionary = async (locale: Locale) => dictionaries[locale]();
    const { notFound } = await getDictionary(lang);
    setDict(notFound);
  };

  useLayoutEffect(() => {
    if (locale && pathname) {
      const lang = pathname.startsWith("/fr") ? "fr" : "en";
      getDict(lang);
      redirect(`/${lang}`);
    } else {
      redirect("/en");
    }
  }, [locale, pathname]);

  if (!dict) {
    // redirect("/en");
    return null;
  }

  return null; // No UI is needed since the user is being redirected

  // return (
  //   <ContentLayout bgColorClass="bg-navy-blue-10" className="min-h-[600px]">
  //     <div className="flex flex-col items-center">
  //       {dict?.label && (
  //         <AppText type="LABEL_LARGE" className="text-royal-blue text-center">
  //           {dict?.label || ""}
  //         </AppText>
  //       )}

  //       <AppText type="HEADLINE_MEDIUM" className="mt-2 text-center max-w-[736px]">
  //         {dict?.heading || ""}
  //       </AppText>

  //       {dict?.subheading && (
  //         <AppText type="SUB_HEADING_SMALL" className="mt-4 text-center max-w-[736px]">
  //           {dict?.subheading || ""}
  //         </AppText>
  //       )}

  //       <div className="lg:flex-row flex flex-col items-center gap-6 mt-8">
  //         <AppButton label={dict?.primaryCtaLabel || ""} link="/" intent="primary" />

  //         <AppButton label={dict?.secondaryCtaLabel || ""} onClick={() => router.back()} intent="secondary" />
  //       </div>
  //     </div>
  //   </ContentLayout>
  // );
};

export default NotFoundComponent;
